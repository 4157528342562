import { toast } from "react-toastify"

export const showErrorToast = (message) => {
  toast(message, {
    type: 'error',
  });
}

export const showSuccessToast = (message) => {
  toast(message, {
    type: 'success',
  });
}

export const showInfoToast = (message) => {
  toast(message, {
    type: 'info',
  });
}