import { ACTION_TYPES } from '../constants/actionTypes';

const initialState = {
  navigationProps: {},
  loggedInUser: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.APP.NAVIGATION_PROPS:
      return {
        ...state,
        navigationProps: {
          ...state.navigationProps,
          ...action.data
        }
      };
    case ACTION_TYPES.APP.LOGGED_IN_USER:
      return {
        ...state,
        loggedInUser: action.data
      };
    default:
      return state;
  }
};

export default reducer;
