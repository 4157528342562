import React, { useEffect, useState } from 'react'
import { styles } from './styles'
import { fetchAdminUnmappedKycUsers, fetchAuditorKycUserList } from '../../../redux/thunks/kyc.thunk';
import { APICallStatus } from '../../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faChevronCircleRight, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { theme } from '../../../constants/theme';
import layout from '../../../constants/layout';
import { getFormattedDateTime } from '../../../service/codeUtils';
import AgentUserMapModal from '../../modals/AgentUserMapModal';
import ClosureDetailsModal from '../../modals/ClosureDetailsModal';
import { fetchClosureTickets } from '../../../redux/thunks/account.thunk';

export default function ClosureRequests() {
  const [tickets, setTickets] = useState([]);
  const [modalUserData, setModalUserData] = useState();

  const onLoad = async () => {
    const response = await fetchClosureTickets();
    console.log(response);
    if(response?.status === APICallStatus.SUCCESS) {
      setTickets(response?.tickets);
    }
  }

  useEffect(() => {
    if(tickets?.length === 0)
      onLoad();
  }, []);

  const onCloseModal = () => {
    onLoad();
    setModalUserData(null);
  }

  return (
    <div className='d-flex flex-column' style={styles.container}>
      <div className="d-flex flex-row justify-space-between" style={styles.tableHeader}>

        <div className="d-flex flex-row justify-content-center" style={styles.tableRowItem}>
          <p style={styles.tableHeaderText}>Full Name</p>
        </div>

        <div className="d-flex flex-row justify-content-center" style={styles.tableRowItem}>
          <p style={styles.tableHeaderText}>Mobile Number</p>
        </div>

        <div className="d-flex flex-row justify-content-center" style={styles.tableRowItem}>
          <p style={styles.tableHeaderText}>Country</p>
        </div>

        <div className="d-flex flex-row justify-content-center" style={styles.tableRowItem}>
          <p style={styles.tableHeaderText}>Request date & time</p>
        </div>

        <div className="d-flex flex-row justify-content-center" style={styles.tableRowItem}>
          <p style={styles.tableHeaderText}>Visa expiry</p>
        </div>

        <div className="d-flex flex-row justify-content-center" style={styles.tableRowLastItem}>
        </div>

      </div>

      <div className="d-flex flex-column" style={styles.tableRowsContainer}>
        {tickets?.length > 0 ? tickets?.map(ticket => <div className="d-flex flex-row justify-space-between" style={styles.tableRow} onClick={() => setModalUserData(ticket)}>
          <div className="d-flex flex-row justify-content-center" style={styles.tableRowItem}>
            <p style={styles.tableHeaderText}>{ticket?.userInfoDetails?.fullName}</p>
          </div>

          <div className="d-flex flex-row justify-content-center" style={styles.tableRowItem}>
            <p style={styles.tableHeaderText}>{ticket?.userDetails?.phoneNumber}</p>
          </div>

          <div className="d-flex flex-row justify-content-center" style={styles.tableRowItem}>
            <p style={styles.tableHeaderText}>{ticket?.userInfoDetails?.countryName}</p>
          </div>

          <div className="d-flex flex-row justify-content-center" style={styles.tableRowItem}>
            <p style={styles.tableHeaderText}>{getFormattedDateTime(ticket?.createdTimestamp)}</p>
          </div>

          <div className="d-flex flex-row justify-content-center" style={styles.tableRowItem}>
            {/* <p style={styles.tableHeaderText}>{user?.city}, {user?.state}</p> */}
          </div>

          <div className="d-flex flex-row justify-content-center align-items-center" style={styles.tableRowLastItem}>
            <FontAwesomeIcon icon={faChevronCircleRight} color={theme.COLOR.ThemePrimary} fontSize={layout.window.toNormH(26)}/>
          </div>
        </div>) : <div className="d-flex flex-column justify-content-center align-items-center" style={styles.emptyContainer}>
            <FontAwesomeIcon icon={faTimesCircle} style={styles.crossIcon}/>
            <p style={styles.emptyText}>No closure requests found</p>
          </div>}
      </div>
      <ClosureDetailsModal visible={modalUserData} ticketDetails={modalUserData} onClose={onCloseModal}/>
    </div>
  )
}
