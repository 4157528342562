import layout from "../../../constants/layout";
import { theme } from "../../../constants/theme";

export const styles = {
  container: {
    padding: layout.window.toNormW(22),
    position: 'relative',
  },
  itemCard: {
    // width: '80%',
    flex: 1,
    height: layout.window.toNormH(160),
    backgroundColor: theme.COLOR.ThemePrimaryOpaque,
    borderRadius: layout.window.toNormW(22),
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: layout.window.toNormH(20),
    marginRight: layout.window.toNormW(30),
  },
  selectedItemCard: {
    flex: 1,
    height: layout.window.toNormH(160),
    backgroundColor: theme.COLOR.ThemePrimaryOpaque,
    borderRadius: layout.window.toNormW(22),
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: layout.window.toNormH(20),
    marginRight: layout.window.toNormW(30),
    borderColor: theme.COLOR.ThemePrimary,
    borderStyle: 'solid'
  },
  itemTitle: {
    fontSize: layout.window.toNormH(18),
    fontFamily: theme.FONT.PrimarySemiBold,
    textAlign: 'center'
  },
  itemCount: {
    fontSize: layout.window.toNormH(30),
    fontFamily: theme.FONT.PrimaryBold,
    textAlign: 'center'
  },
  listContainer: {
    flex: 1,
    width: '100%',
    paddingLeft: layout.window.toNormW(22),
    paddingRight: layout.window.toNormW(22),
    // backgroundColor: 'red'
  },
  horizontalLine: {
    width: '100%',
    heigth: '100px',
    backgroundColor: theme.COLOR.Grey
  },
  tableHeader: {
    backgroundColor: theme.COLOR.LightGrey,
    paddingLeft: layout.window.toNormW(22),
    paddingRight: layout.window.toNormW(22),
    paddingTop: layout.window.toNormW(10),
    paddingBottom: layout.window.toNormW(10),
    borderRadius: layout.window.toNormW(6),
  },
  tableRowItem: {
    flex: 1,
    marginRight: layout.window.toNormW(6),
  },
  tableRowLastItem: {
    width: layout.window.toNormW(50),
  },
  tableHeaderText: {
    fontSize: layout.window.toNormH(20),
    fontFamily: theme.FONT.PrimarySemiBold,
    margin: 0,
    textAlign: 'center'
  },
  tableRowsContainer: {
    flex: 1,
    // backgroundColor: 'red',
    maxHeight: layout.window.toNormH(500),
    overflow: 'scroll',
    paddingTop: layout.window.toNormH(10),
  },
  tableRow: {
    backgroundColor: theme.COLOR.WhitePrimary,
    paddingLeft: layout.window.toNormW(22),
    paddingRight: layout.window.toNormW(22),
    paddingTop: layout.window.toNormW(20),
    paddingBottom: layout.window.toNormW(20),
    // borderRadius: layout.window.toNormW(6),
    borderBottomWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderStyle: 'solid',
    borderColor: theme.COLOR.LightGrey
  },
  emptyContainer: {
    flex: 1,
    // backgroundColor: 'red'
  },
  crossIcon: {
    fontSize: layout.window.toNormH(80),
    color: theme.COLOR.LightGrey,
    // fontFamily: theme.FONT.PrimarySemiBold,
  },
  emptyText: {
    fontSize: layout.window.toNormH(30),
    fontFamily: theme.FONT.PrimarySemiBold,
    marginTop: layout.window.toNormH(20),
    color: theme.COLOR.LightGrey,
  }
}