import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import S3 from 'react-aws-s3';
import { APICallStatus } from '../../constants';

export const uploadToS3 = async (file, fileName, bucketName) => {
  try {
    const config = {
      bucketName: bucketName,
      // dirName: 'media', /* optional */
      region: 'ap-south-1',
      accessKeyId: 'AKIAQPCDA6X6QH22JCX3',
      secretAccessKey: 'k8EQwFfQIMLZuE6RLeepRHxnW5AUhjrZD1+t5zBC',
      // s3Url: 'https:/your-custom-s3-url.com/', /* optional */
    };
    const ReactS3Client = new S3(config);
    const response = await ReactS3Client.uploadFile(file, fileName);
    console.log(response,' xyzxyz s3 response');
  } catch (error) {
    console.log(error, 'xyzxyz s3 upload error')
  }
}

export const uploadToS3Client = async (file, fileName, bucketProvidedName) => {
  try {
    const s3Client = new S3Client({
      region: "ap-south-1", // Replace with your AWS region
      credentials: {
        accessKeyId: "AKIAQPCDA6X6ZTA4U3GX",
        secretAccessKey: "I4ecwWHhPlzfRwJTivkXDNPLa2acQGz2wLKwbFHc",
      },
    });
    const base64Data = file; // Replace with your actual base64 data
    // AWS_ACCESS_KEY=AKIAQPCDA6X67LWLZPVN;AWS_SECRET_KEY=FaSOoSAr9ABzGJ22duhhK2ehd2Rj2ZdT6jtizwwn;AWS_REGION=ap-south-1
    // Decode the base64 data
    const binaryData = new Uint8Array(atob(base64Data).split("").map(char => char.charCodeAt(0)));
    const bucketName = bucketProvidedName;
    const objectKey = fileName;
    const uploadParams = {
      Bucket: bucketName,
      Key: objectKey,
      Body: binaryData,
      // __dirname: 'passport'
    };
    const data = await s3Client.send(new PutObjectCommand(uploadParams));
    console.log(data, 'xyzxyz s3 response');
    return {
      status: APICallStatus.SUCCESS
    }
  } catch (error) {
    console.log(error, 'xyzxyz s3 upload error')
    return {
      status: APICallStatus.ERROR
    }
  }
}