import React, { useEffect, useState } from 'react'
import { BallTriangle } from 'react-loader-spinner'
import { useNavigate } from 'react-router-dom';
import { APICallStatus } from '../../constants';
import layout from '../../constants/layout';
import store from '../../redux/store';
import { saveTokens } from '../../redux/thunks/auth.thunk';
import { styles } from './styles';
import { theme } from '../../constants/theme';
import Lottie from 'react-lottie';
import LogoAnimation from '../../assets/lotties/logoLoaderAnimation.json';
import { fetchLoggedInProfile, isTokenValid } from '../../service/AuthService';

export default function AuthVerification() {
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('Something went wrong');
  const navigate = useNavigate();

  const onLoad = async () => {
    setIsLoading(true);
    const response = await isTokenValid();
    if(response?.status === APICallStatus.SUCCESS) {
      await fetchLoggedInProfile();
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  }

  useEffect(() => {
    onLoad();
  })

  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: LogoAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <>
      {isLoading ? <div style={styles.loaderContainer}>
        <Lottie options={defaultOptions}
          height={layout.window.toNormW(200)}
          width={layout.window.toNormW(200)}
        />
      </div> : <div style={styles.container}>
        <p style={styles.errorText}>{errorMessage}</p>
      </div>}
    </>
  )
}
