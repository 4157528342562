import React, { useEffect, useState } from 'react'
import { Button, Dropdown, DropdownButton, Modal } from 'react-bootstrap'
import { styles } from './styles'
import { getFormattedDateTime } from '../../../service/codeUtils'
import Input from '../../../elements/Input'
import { auditorKycSubmit, fetchAllAgents, mapBookingToAgent } from '../../../redux/thunks/kyc.thunk'
import CustomButton from '../../../elements/CustomButton'
import { APICallStatus, KycAction } from '../../../constants'
import layout from '../../../constants/layout'
import { showErrorToast, showSuccessToast } from '../../../service/toast'
import { actionClosureRequest, fetchClosureWalletBalance } from '../../../redux/thunks/account.thunk'


const ClosureAction  = {
  approve: 'APPROVE',
  reject: 'REJECT'
}

export default function ClosureDetailsModal({
  visible,
  ticketDetails,
  onClose,
}) {

  const [isLoading, setIsLoading] = useState(false);
  const [comments, setComments] = useState();
  const [walletBalance, setWalletBalance] = useState();


  const loadBalance = async () => {
    const response = await fetchClosureWalletBalance({uuid: ticketDetails?.userDetails?.uuid});
    if(response?.status === APICallStatus.SUCCESS) {
      setWalletBalance(response?.balance);
    }
  }

  useEffect(() => {
    if(visible) {
      loadBalance();
    }
  }, [visible])

  const onSubmit = async (actionType) => {
    setIsLoading(actionType);
    const response = await actionClosureRequest({
      uuid: ticketDetails?.userDetails?.uuid,
      ticketId: ticketDetails?.id,
      action: actionType,
      comment: comments
    });
    setIsLoading(null);
    if(response?.status === APICallStatus.SUCCESS) {
      onClose();
      showSuccessToast(actionType === ClosureAction.approve ? "User account closed successfully" : "Closure request rejected");
    } else {
      showErrorToast(response?.message);
    }
  }

  return (
    <Modal show={visible} onHide={onClose} size="lg" >
      <Modal.Header closeButton>
        <Modal.Title>Account closure details</Modal.Title>
      </Modal.Header>
      <Modal.Body className='d-flex flex-column align-items-center'>
        {/* <img src={userDetails?.userProfilePhoto} style={styles.userProfile}/> */}
        <p className="mb-0" style={styles.userName}>{ticketDetails?.userInfoDetails?.fullName}</p>
        <p className="mb-0" style={styles.country}>{ticketDetails?.userDetails?.phoneNumber}</p>

        <div style={styles.horizontalLine}/>

        <div className="d-flex justify-content-between" style={{width: '100%'}}>
          <div style={styles.fieldContainer}>
            <p className="m-0" style={styles.fieldTitle}>Country</p>
            <p className="m-0" style={styles.fieldValue}>{ticketDetails?.userInfoDetails?.countryName}</p>
          </div>
          <div className="d-flex flex-column align-items-end" style={styles.fieldContainer}>
            <p className="m-0" style={styles.fieldTitle}>Visa Expiry</p>
            <p className="m-0" style={styles.fieldValue}>--</p>
          </div>
        </div>
        <div className="d-flex justify-content-between" style={{width: '100%'}}>
          <div style={styles.fieldContainer}>
            <p className="m-0" style={styles.fieldTitle}>Requested Date & Time</p>
            <p className="m-0" style={styles.fieldValue}>{getFormattedDateTime(ticketDetails?.createdTimestamp)}</p>
          </div>
          <div className="d-flex flex-column align-items-end" style={styles.fieldContainer}>
            <p className="m-0" style={styles.fieldTitle}>Wallet Balance</p>
            <p className="m-0" style={styles.fieldValue}>{walletBalance ? walletBalance : "--"}</p>
          </div>
        </div>

        <div style={styles.horizontalLine}/>

        <div style={styles.fieldContainer}>
          <Input label={'Reason*'} value={comments} onChange={setComments}/>
        </div>

      </Modal.Body>
      <Modal.Footer>
        <div className='d-flex flex-row'>
          <CustomButton title={"Reject"} isDisabled={comments?.length === 0 || isLoading} onClick={() => onSubmit(ClosureAction.reject)} isLoading={isLoading === ClosureAction.reject} buttonStyles={{flex: 1, backgroundColor: 'red', borderColor: 'red', marginRight: layout.window.toNormW(22)}} style={{marginRight: layout.window.toNormW(22)}}/>
          <CustomButton title={"Approve"} isDisabled={comments?.length === 0 || isLoading} onClick={() => onSubmit(ClosureAction.approve)} isLoading={isLoading === ClosureAction.approve} buttonStyles={{flex: 1}}/>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
