export const theme = {
  COLOR: {
    ThemePrimary: '#613AE7',
    Grey: '#898989',
    Grey100: '#767676',
    Grey200: '#EEEEEE',
    WhitePrimary: '#ffffff',
    LightGrey: '#CAD1DA',
    LightGrey100: '#F5F8F9',
    SuccessGreen: '#16BF69',
    SuccessGreenOpaque: '#16BF6930',
    Red: '#FF656F',
    YellowOrange: '#ffb200',
    YellowOrangeDark: '#FF9300',
    ThemePrimaryOpaque: '#613AE730',
    RedOpaque: '#FF656F30',
  },
  FONT: {
    PrimaryRegular: 'PrimaryRegular',
    PrimarySemiBold: 'PrimarySemiBold',
    PrimaryBold: 'PrimaryBold',
    PrimaryLight: 'PrimaryLight',
    PrimaryRegularItalic: 'PrimaryRegularItalic',
    PrimarySemiBoldItalic: 'PrimarySemiBoldItalic',
    PrimaryLight: 'PrimaryLight',
    PrimaryBoldItalic: 'PrimaryBoldItalic',
  }
}