import { useNavigate } from "react-router-dom";
import { styles } from './styles';
import { theme } from "../../../constants/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faCamera, faCameraAlt, faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import layout from "../../../constants/layout";
import FooterCtaContainer from "../../../elements/FooterCtaContainer";
import Input from "../../../elements/Input";
import { useCallback, useRef, useState } from "react";
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import Webcam from "react-webcam";
import { useSelector } from "react-redux";
import { navigationPropsSelector } from "../../../redux/selectors/app";
import { getFormattedDateTime } from "../../../service/codeUtils";
import { agentKycSubmit, verifyKycStartOtp } from "../../../redux/thunks/kyc.thunk";
import { APICallStatus, KycAction } from "../../../constants";
import { uploadToS3, uploadToS3Client } from "../../../service/AwsService";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { showErrorToast, showSuccessToast } from "../../../service/toast";

function UserDetails({

}) {
  const navigate = useNavigate();
  const userDetails = useSelector(navigationPropsSelector).kycUserDetails;
  console.log(userDetails, 'xyzxyz')
  const frontWebcamRef = useRef(null);
  const backWebcamRef = useRef(null);
  const visaWebcamRef = useRef(null);
  const [stepNumber, setStepNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [kycOtp, setKycOtp] = useState('');
  const [passportNumber, setPassportNumber] = useState('');
  const [showFrontPassportCamera, setShowFrontPassportCamera] = useState(false);
  const [frontPassportImg, setFrontPassportImg] = useState(null);
  const [showBackPassportCamera, setShowBackPassportCamera] = useState(false);
  const [backPassportImg, setBackPassportImg] = useState(null);
  const [showVisaCamera, setShowVisaCamera] = useState(false);
  const [visaImg, setVisaImg] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [comments, setComments] = useState();
  const videoConstraints = {
    // facingMode: { exact: "environment" }
    facingMode: 'environment'
  };

  const otpVerifyClick = async () => {
    const response = await verifyKycStartOtp({
      otp: kycOtp,
      bookingId: userDetails?.bookingId,
    });

    if(response?.status === APICallStatus.SUCCESS) {
      return true;
    } else {
      showErrorToast(response?.message);
      return false;
    }
  }

  const handleFrontPassportCapture = useCallback(
    () => {
      const imageSrc = frontWebcamRef.current.getScreenshot();
      setFrontPassportImg(imageSrc);
    },
    [frontWebcamRef]
  );
  const handleBackPassportCapture = useCallback(
    () => {
      const imageSrc = backWebcamRef.current.getScreenshot();
      setBackPassportImg(imageSrc);
    },
    [backWebcamRef]
  );

  const handleVisaCapture = useCallback(
    () => {
      const imageSrc = visaWebcamRef.current.getScreenshot();
      setVisaImg(imageSrc);
    },
    [visaWebcamRef]
  );

  const getKycDivision = () => {
    switch (stepNumber) {
      case 1:
        return (<div style={styles.formContainer}>
          <Input label={'Verification code'} value={kycOtp} onChange={setKycOtp} />
        </div>);
      case 2:
        return (<div style={styles.formContainer}>
          <Input label={'Passport Number'} value={passportNumber} onChange={setPassportNumber} />
        </div>);
      case 3:
        return (
          <div style={styles.formContainer}>
            <p className="m-0" style={styles.fieldTitle}>Upload passport front page</p>
            <div className="d-flex justify-content-center align-items-center" style={styles.cameraContainer} onClick={() => {
              setShowFrontPassportCamera(true);
            }}>
              {frontPassportImg ? <img src={frontPassportImg} style={styles.imgPreview}/> : <>
                {!showFrontPassportCamera ? <FontAwesomeIcon icon={faCamera} fontSize={layout.window.toNormH(60)} color={theme.COLOR.Grey}/> :
                  <Webcam
                    audio={false}
                    height={layout.window.toNormH(240)}
                    screenshotFormat="image/png"
                    ref={frontWebcamRef}
                    videoConstraints={videoConstraints}
                />}
              </>}
            </div>
            {showFrontPassportCamera ? <div className="d-flex flex-row justify-content-center mt-1">
              <button style={styles.captureBtn} onClick={frontPassportImg ? () => {setFrontPassportImg(null)} : handleFrontPassportCapture}>{frontPassportImg ? 'Reset' : 'Capture'}</button>
            </div> : null}
          </div>
        );
      case 4:
        return (
          <div style={styles.formContainer}>
            <p className="m-0" style={styles.fieldTitle}>Upload passport back page</p>
            <div className="d-flex justify-content-center align-items-center" style={styles.cameraContainer} onClick={() => {
              setShowBackPassportCamera(true);
            }}>
              {backPassportImg ? <img src={backPassportImg} style={styles.imgPreview}/> : <>
                {!showBackPassportCamera ? <FontAwesomeIcon icon={faCamera} fontSize={layout.window.toNormH(60)} color={theme.COLOR.Grey}/> :
                  <Webcam
                    audio={false}
                    height={layout.window.toNormH(240)}
                    screenshotFormat="image/png"
                    ref={backWebcamRef}
                    videoConstraints={videoConstraints}
                />}
              </>}
            </div>
            {showBackPassportCamera ? <div className="d-flex flex-row justify-content-center mt-1">
              <button style={styles.captureBtn} onClick={backPassportImg ? () => {setBackPassportImg(null)} : handleBackPassportCapture}>{backPassportImg ? 'Reset' : 'Capture'}</button>
            </div> : null}
          </div>
        );
      case 5:
        return (
          <div style={styles.formContainer}>
            <p style={styles.formTitle}>Select VISA start date</p>
            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} style={styles.datePicker}/>
            <p style={styles.formTitle}>Select VISA expiry date</p>
            <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} style={styles.datePicker}/>
          </div>
        );
      case 6:
        return (
          <div style={styles.formContainer}>
            <p className="m-0" style={styles.fieldTitle}>Upload visa</p>
            <div className="d-flex justify-content-center align-items-center" style={styles.cameraContainer} onClick={() => {
              setShowVisaCamera(true);
            }}>
              {visaImg ? <img src={visaImg} style={styles.imgPreview}/> : <>
                {!showVisaCamera ? <FontAwesomeIcon icon={faCamera} fontSize={layout.window.toNormH(60)} color={theme.COLOR.Grey}/> :
                  <Webcam
                    audio={false}
                    height={layout.window.toNormH(240)}
                    screenshotFormat="image/png"
                    ref={visaWebcamRef}
                    videoConstraints={videoConstraints}
                />}
              </>}
            </div>
            {showVisaCamera ? <div className="d-flex flex-row justify-content-center mt-1">
              <button style={styles.captureBtn} onClick={visaImg ? () => {setVisaImg(null)} : handleVisaCapture}>{visaImg ? 'Reset' : 'Capture'}</button>
            </div> : null}
          </div>
        );
      case 7:
        return (
          <div style={styles.formContainer}>
            <Input label={'Comments (Optional)'} value={comments} onChange={setComments} />
          </div>
        )
      case 0:
      default:
        break;
    }
  }

  const getCtaTitle = () => {
    switch (stepNumber) {
      case 0:
        return 'Start KYC'
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
        return 'Next';
      case 7:
        return 'Submit';
    }
  }

  const submitUserKyc = async (action) => {
    const response = await agentKycSubmit({
      bookingId: userDetails?.bookingId,
      passportNumber,
      visaStartDate: startDate.getTime(),
      visaEndDate: endDate.getTime(),
      passportFrontImage: `passport/${userDetails.uuid}_front.png`,
      passportBackImage: `passport/${userDetails.uuid}_front.png`,
      visaImage: `visa/${userDetails.uuid}_visa.png`,
      action,
      comments
    });
    if(response?.status === APICallStatus.ERROR) {
      showErrorToast(response?.message);
    }
    return response?.status;
  }

  const onClickNext = async () => {
    switch (stepNumber) {
      case 0:
        if(userDetails?.otpVerificationRequired)
          setStepNumber(stepNumber+1)
        else 
          setStepNumber(stepNumber+2)
        break;
      case 1:
        setIsLoading(true);
        const verified = await otpVerifyClick();
        setIsLoading(false);
        if (verified) setStepNumber(stepNumber + 1);
        break;
      case 2:
        if(passportNumber?.match('^(?!^0+$)[a-zA-Z0-9]{3,20}$'))
          setStepNumber(stepNumber + 1);
        else
        return;
        break;
      case 3:
        // if(!frontPassportImg) return;
        setIsLoading(true);
        const responseFront = await uploadToS3Client(frontPassportImg.split('data:image/png;base64,')[1], `passport/${userDetails?.uuid}_front.png`, 'monyy-files-prod');
        setIsLoading(false);
        if(responseFront?.status === APICallStatus.SUCCESS) 
          setStepNumber(stepNumber + 1);
        else 
          showErrorToast('Error uploading image');
        break;
      case 4:
        setIsLoading(true);
        const responseBack = await uploadToS3Client(backPassportImg.split('data:image/png;base64,')[1], `passport/${userDetails?.uuid}_back.png`, 'monyy-files-prod');
        setIsLoading(false);
        if(responseBack?.status === APICallStatus.SUCCESS) 
          setStepNumber(stepNumber + 1);
        else 
          showErrorToast('Error uploading image');
        break;
      case 5:
        if(startDate < endDate && endDate > new Date()) 
          setStepNumber(stepNumber + 1);
        else return;
        break;
      case 6:
        setIsLoading(true);
        const responseVisa = await uploadToS3Client(visaImg.split('data:image/png;base64,')[1], `visa/${userDetails?.uuid}_visa.png`, 'monyy-files-prod');
        setIsLoading(false);
        if(responseVisa?.status === APICallStatus.SUCCESS) 
          setStepNumber(stepNumber + 1);
        else 
          showErrorToast('Error uploading image');
        break;
      case 7:
        setIsLoading(true);
        const response = await submitUserKyc(KycAction.APPROVE);
        setIsLoading(false);
        if(response === APICallStatus.SUCCESS)
        {
          showSuccessToast("User KYC submitted successfully");
          navigate(-1);
        }
        return;
      default:
        break;
    }
  }

  const getCtaDisabled = () => {
    switch (stepNumber) {
      case 0:
        return false;
      case 1:
        return !(kycOtp?.length === 6)
      case 2:
        return !passportNumber?.match('^(?!^0+$)[a-zA-Z0-9]{3,20}$')
      case 3:
        return !frontPassportImg;
      case 4:
        return !backPassportImg;
      case 5:
        return !(startDate < endDate && endDate > new Date());
      case 6:
        return !visaImg;
      default:
        break;
    }
  }
 
  return (
    <>
      <div className="d-flex flex-column"  style={styles.container}>
        <div className="d-flex flex-row align-items-center" style={styles.userInfoContainer}>
          <img src={userDetails?.userProfilePhoto} style={styles.userImage}/>
          <div>
          <p className="m-0" style={styles.userName}>{userDetails?.userName}</p>
          <p className="m-0" style={styles.country}>{userDetails?.userCountryName}</p>
          </div>
        </div>

        <div style={styles.fieldContainer}>
          <p className="m-0" style={styles.fieldTitle}>Preferred Date and Time</p>
          <p className="m-0" style={styles.fieldValue}>{getFormattedDateTime(userDetails?.preferredDateTime)}</p>
        </div>

        <div style={styles.fieldContainer}>
          <p className="m-0" style={styles.fieldTitle}>Current Address</p>
          <a className="m-0" style={styles.fieldValue}>{userDetails?.addressLine1}, {userDetails?.addressLine2}, {userDetails?.city}, {userDetails?.state} {userDetails?.pincode}</a>
        </div>

        <div className="d-flex justify-content-between">
          <div style={styles.fieldContainer}>
            <p className="m-0" style={styles.fieldTitle}>Contact Number</p>
            <a className="m-0" style={styles.fieldValue}>{userDetails?.userPhoneNumber}</a>
          </div>
          <div className="d-flex flex-column align-items-end" style={styles.fieldContainer}>
            <p className="m-0" style={styles.fieldTitle}>Alternate Number</p>
            {userDetails?.alternatePhoneNumber ? <a className="m-0" style={styles.fieldValue}>{userDetails?.alternatePhoneNumber}</a> : <p className="m-0" style={styles.fieldValue}>--</p>}
          </div>
        </div>

        {stepNumber !== 0 ? <div className="d-flex flex-column" style={styles.kycDivision}>
          <div style={styles.horizontalLine}></div>
          <div className="d-flex justify-content-center align-items-center" style={styles.stepsIndicatorContainer}>
            {[1,2,3,4,5,6,7].map(step => <span style={step === stepNumber ? styles.currentStepIndicator : styles.stepIndicator}></span>)}
          </div>
          {getKycDivision()}
        </div> : null}
      </div>
      <FooterCtaContainer 
        primaryAttributes={{
          title: getCtaTitle(),
          onClick: onClickNext,
          isLoading: isLoading,
          isDisabled: getCtaDisabled(),
        }} 
      />
    </>
  );
}

export default UserDetails;
