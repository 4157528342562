
const base_width = 428;
const base_height = 926;

// const screen_width = Dimensions.get("window").width
// const screen_height = Dimensions.get("window").height
const screen_width = window.innerWidth;
const screen_height = window.innerHeight;

const deviceWidth = screen_width > 500 ? base_width : screen_width;
const deviceHeight = screen_height > 1000 ? base_height : screen_height;
//Platform.OS == 'web' && screen_height > base_height ? base_height : screen_height;
// the zeplin screens are provided for a screen resolution of 375 x 812 px
// to make the screen layout equivalent on all screen sizes, we use the original pixel size provided in zeplin
// the sizes are scaled according to the device specific width x height using a normalization factor
// example: {height: 30 * window.normH, width: 100 * window.normW}

export default {
  window: {
    deviceWidth,
    deviceHeight,
    normW: Math.fround(deviceWidth / base_width),
    normH: Math.fround(deviceHeight / base_height),
    toNormW: (width, dW = deviceWidth) => {
      return (width * dW) / base_width;
    },
    toNormH: (height, dH = deviceHeight) => {
      return (height * dH) / base_height;
    },
  },
  isSmallDevice: deviceWidth < 375,
  aspect_16To9: Math.fround(16 / 9),
  isMobileDevice: screen_width < 800,
  deviceHeightForWeb : () => {
    const deviceHeight = screen_height;
    if (deviceHeight < 800 && deviceHeight > 400) {
      return deviceHeight * 0.9;
    } else if (deviceHeight > 800) {
      return 700;
    } else if (deviceHeight < 400) {
      return deviceHeight;
    }
    return deviceHeight
  }
};
