import { useNavigate } from "react-router-dom";
import { styles } from './styles';
import { theme } from "../../../constants/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faCheckSquare, faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { APICallStatus } from "../../../constants";
import { fetchAuditorKycUserList } from "../../../redux/thunks/kyc.thunk";
import AuditorUserDetailsModal from "../../modals/AuditorUserDetailsModal";
import layout from "../../../constants/layout";
import AssignAgents from "../AssignAgents";
import ClosureRequests from "../ClosureRequests";

function AdminDashboard({

}) {
  const navigate = useNavigate();
  const CardOptions = {
    ASSIGN_AGENT: 'ASSIGN_AGENT',
    ADD_AGENT_AUDTOR: 'ADD_AGENT_AUDITOR',
    CLOSURE_REQUESTS: 'CLOSURE_REQUESTS',
  };

  const cards = [{
    key: CardOptions.ASSIGN_AGENT,
    value: 'Assign Agent'
  }, {
    key: CardOptions.ADD_AGENT_AUDTOR,
    value: 'Add new agent or auditor'
  }, {
    key: CardOptions.CLOSURE_REQUESTS,
    value: 'Account closure requests'
  }];

  const [selectedOption, setSelectedOption] = useState(CardOptions.ASSIGN_AGENT);

  const getDivision = () => {
    switch (selectedOption) {
      case CardOptions.ASSIGN_AGENT:
        return <AssignAgents/>;
      case CardOptions.CLOSURE_REQUESTS:
        return <ClosureRequests/>;
      default:
        return null;
    }
  }
 
  return (
    <>
      <div className="d-flex flex-row"  style={styles.container}>
        {cards?.map(card => <div className="d-flex flex-column justify-content-center" style={selectedOption === card.key ? styles.selectedItemCard : styles.itemCard} onClick={() => setSelectedOption(card.key)}>
          <p className="m-0" style={styles.itemTitle}>{card?.value}</p>
        </div>)}
      </div>
      <div className="d-flex flex-column" style={styles.listContainer}>
        {getDivision()}
      </div>
    </>
  );
}

export default AdminDashboard;
