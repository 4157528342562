const AccessRoles = {
  AGENT: 'ROLE_AGENT',
  AUDITOR: 'ROLE_AUDITOR',
  ADMIN: 'ROLE_ADMIN',
}

const APICallStatus = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
}

const KycAction = {
  APPROVE: 'APPROVE',
  REJECT: 'REJECT',
}

export {
  AccessRoles,
  APICallStatus,
  KycAction,
}