import layout from "../../../constants/layout";
import { theme } from "../../../constants/theme";

export const styles = {
  container: {
    position: 'relative',
    flex: 1,
    minHeight: '100vh'
  },
  leftContainer: {
    flex: 2,
    backgroundColor: theme.COLOR.ThemePrimary,
  },
  rightContainer: {
    flex: 1,
    padding: layout.window.toNormW(42),
  },
  monyLogo: {
    width: layout.window.toNormW(150),
  },
  title: {
    fontSize: layout.window.toNormH(42),
    fontFamily: theme.FONT.PrimaryBold,
    marginTop: layout.window.toNormH(26),
    color: theme.COLOR.WhitePrimary
  },
  formContainer: {
    width: '100%',
    marginTop: layout.window.toNormH(22)
  },
  inputContainer: {
    marginTop: layout.window.toNormH(22)
  },
  otpCta: {
    fontSize: layout.window.toNormH(18),
    fontFamily: theme.FONT.PrimarySemiBold,
    color: theme.COLOR.ThemePrimary,
    textAlign: 'right',
    marginTop: layout.window.toNormH(-46),
    marginRight: layout.window.toNormW(22),
    // right: layout.window.toNormH(36),
    // position: 'absolute',
    // bottom: layout.window.toNormH(20),
  },
  timerText: {
    textAlign: 'right',
    marginTop: layout.window.toNormH(10),
    fontSize: layout.window.toNormH(16),
    fontFamily: theme.FONT.PrimaryRegular,
  },
  loginCta: {
    marginTop: layout.window.toNormH(42)
  },
  fieldTitle: {
    fontSize: layout.window.toNormH(16),
    fontFamily: theme.FONT.PrimarySemiBold,
    color: theme.COLOR.Grey
  },
  dropdownBtn: {
    width: '100%!important',
    // backgroundColor: 'white'
  }
}