import layout from "../../../constants/layout";
import { theme } from "../../../constants/theme";

export const styles = {
  container: {
    padding: layout.window.toNormW(22),
    position: 'relative'
  },
  itemCard: {
    width: '100%',
    height: layout.window.toNormH(160),
    backgroundColor: theme.COLOR.ThemePrimaryOpaque,
    borderRadius: layout.window.toNormW(22),
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: layout.window.toNormH(20),
  },
  itemTitle: {
    fontSize: layout.window.toNormH(18),
    fontFamily: theme.FONT.PrimarySemiBold,
  },
  itemCount: {
    fontSize: layout.window.toNormH(30),
    fontFamily: theme.FONT.PrimaryBold,
  },
}