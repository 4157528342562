export const formatCurrency = (givenAmount) => {
  if(!givenAmount) {
    return givenAmount?.toString();
  }
  const amount = typeof givenAmount === 'string' ? givenAmount : givenAmount.toString();
  const decimalAmount = amount.split('.')[1];
  const roundOfAmount = amount.split('.')[0]
  // console.log(decimalAmount, 'xyzxyz')
  let result = '';
  let digitCount = 0;
  let firstComma = false;
  for(let i = roundOfAmount.length - 1; i >= 0; i--) {
    result += roundOfAmount.charAt(i);
    digitCount++;
    if(!firstComma && digitCount === 3 && i !== 0) {
      result += ',';
      digitCount = 0;
      firstComma = true;
    } else if(firstComma && digitCount === 2 && i !== 0) {
      result += ',';
      digitCount = 0;
    }
  }
  const formattedAmount = result?.split('').reverse().join('');
  return decimalAmount && decimalAmount.length > 0 ? 
    `${formattedAmount}.${decimalAmount.substring(0,2)?.length === 1 ? `${decimalAmount.substring(0,2)}0` : decimalAmount.substring(0,2)}` : formattedAmount;
}

export const getFormattedDate = (timestamp, format = 'dd/mm/yyyy') => {
  const DateMonths = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  
  const DateWeekDays = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];
  const dateTime = new Date(parseInt(timestamp));
  const day = dateTime?.getDate() < 10 ? `0${dateTime?.getDate()}` : `${dateTime.getDate()}`;
  const month = dateTime?.getMonth()+1 < 10 ? `0${dateTime?.getMonth()+1}` : `${dateTime.getMonth()}`;
  const year = dateTime?.getFullYear();

  switch (format) {
    case 'dd month yyyy':
      return `${day} ${DateMonths[dateTime?.getMonth()]} ${year}`
    case 'dd/mm/yyyy':
    default:
      return `${day}/${month}/${year}`;
  }
}

export const getPlatform = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/android/i.test(userAgent)) {
    return "Android";
  }
  else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "web";
}