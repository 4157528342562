import { applyMiddleware, createStore, compose, Store } from 'redux';
import reduxThunk from 'redux-thunk';
import { createLogger } from 'redux-logger';

// TODO: uncomment the below line
// import Reactotron from '../../../ReactotronConfig';

import reducers from '../reducers';

let middlewares = [reduxThunk];
// if (__DEV__) {
  const loggerMiddleware = createLogger();
  middlewares = [...middlewares, loggerMiddleware];
// }
let enhancer = compose(applyMiddleware(...middlewares));
// if (RNConfig.NODE_ENV !== 'production' || __DEV__) {

//   // TODO: uncomment the below line
//   // const reactotronEnhancer = Reactotron && Reactotron.createEnhancer();
//   enhancer = compose(
//     applyMiddleware(...middlewares),
//     // TODO: uncomment the below line
//     // reactotronEnhancer
//   );
// }

const store = createStore(reducers, enhancer);

export default store;
