import layout from "../../../constants/layout";
import { theme } from "../../../constants/theme";

export const styles = {
  container: {
    display: 'flex',
    flex: 1,
    padding: layout.window.toNormW(22),
    position: 'relative'
  },
  userInfoContainer: {

  },
  userImage: {
    width: layout.window.toNormW(60),
    height: layout.window.toNormW(60),
    objectFit: 'contain',
    // resizeMode: 'contain',
  },
  userName: {
    fontSize: layout.window.toNormH(24),
    fontFamily: theme.FONT.PrimarySemiBold,
    paddingLeft: layout.window.toNormW(12),
  },
  country: {
    fontSize: layout.window.toNormH(16),
    fontFamily: theme.FONT.PrimarySemiBold,
    paddingLeft: layout.window.toNormW(12),
    color: theme.COLOR.Grey
  },
  fieldContainer: {
    marginTop: layout.window.toNormW(12),
  },
  fieldTitle: {
    fontSize: layout.window.toNormH(16),
    fontFamily: theme.FONT.PrimarySemiBold,
    color: theme.COLOR.Grey
  },
  fieldValue: {
    fontSize: layout.window.toNormH(20),
    fontFamily: theme.FONT.PrimarySemiBold,
    // color: theme.COLOR.Grey
  },
  kycDivision: {
    flex: 1,
    width: '100%',
    marginTop: layout.window.toNormW(12),
  },
  horizontalLine: {
    width: '100%',
    height: layout.window.toNormH(1),
    backgroundColor: theme.COLOR.Grey,
    marginBottom: layout.window.toNormW(12),
  },
  stepIndicator: {
    width: layout.window.toNormW(12),
    height: layout.window.toNormW(12),
    borderRadius: layout.window.toNormW(50),
    backgroundColor: theme.COLOR.Grey,
    marginLeft: layout.window.toNormW(5),
    marginRight: layout.window.toNormW(5),
  },
  currentStepIndicator: {
    width: layout.window.toNormW(12),
    height: layout.window.toNormW(12),
    borderRadius: layout.window.toNormW(50),
    backgroundColor: theme.COLOR.ThemePrimary,
    marginLeft: layout.window.toNormW(5),
    marginRight: layout.window.toNormW(5),
  },
  formContainer: {
    marginTop: layout.window.toNormW(22),
  },
  cameraContainer: {
    width: '100%',
    height: layout.window.toNormH(250),
    marginTop: layout.window.toNormW(12),
    borderRadius: layout.window.toNormW(12),
    borderStyle: 'dashed',
    borderColor: theme.COLOR.LightGrey
  },
  imgPreview: {
    width: '98%',
    height: layout.window.toNormH(236),
    borderRadius: layout.window.toNormW(12),
  },
  datePicker: {
    flex: 1,
    width: '100% !important'
  },
  formTitle: {
    fontSize: layout.window.toNormH(16),
    fontFamily: theme.FONT.PrimarySemiBold,
    color: theme.COLOR.Grey,
    marginBottom: layout.window.toNormH(12),
    marginTop: layout.window.toNormH(22),
  }
}