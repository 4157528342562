import React from "react";
import { ToastContainer } from "react-toastify";

const WebWrapper = ({ isWebWrapper, children }) => {

  if(isWebWrapper) {
    return <div className='d-flex flex-column' style={{flex: 1, width: '100%', minWidth: '700px', minHeight: '100vh'}}>
      <ToastContainer 
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {children}
    </div>;
  } else {
    return <div className='d-flex flex-column' style={{flex: 1, width: '100%', minHeight: '100vh'}}>
      <ToastContainer 
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {children}
    </div>
  }
};

export default WebWrapper;
