import layout from "../../../constants/layout";
import { theme } from "../../../constants/theme";

export const styles = {
  userProfile: {
    width: layout.window.toNormW(100),
    height: layout.window.toNormW(100),
  },
  userName: {
    fontSize: layout.window.toNormH(22),
    fontFamily: theme.FONT.PrimarySemiBold,
    marginTop: layout.window.toNormH(12),
  },
  country: {
    fontSize: layout.window.toNormH(18),
    fontFamily: theme.FONT.PrimaryRegular,
    color: theme.COLOR.Grey
    // marginTop: layout.window.toNormH(12),
  },
  fieldContainer: {
    width: '100%',
    marginTop: layout.window.toNormW(22),
  },
  fieldTitle: {
    fontSize: layout.window.toNormH(16),
    fontFamily: theme.FONT.PrimarySemiBold,
    color: theme.COLOR.Grey
  },
  fieldValue: {
    fontSize: layout.window.toNormH(20),
    fontFamily: theme.FONT.PrimarySemiBold,
    // color: theme.COLOR.Grey
  },
  horizontalLine: {
    width: '100%',
    height: layout.window.toNormH(2),
    backgroundColor: theme.COLOR.Grey,
    marginTop: layout.window.toNormW(22),
  },
  sectionTitle: {
    fontSize: layout.window.toNormH(20),
    fontFamily: theme.FONT.PrimarySemiBold,
    marginTop: layout.window.toNormW(22),
    // color: theme.COLOR.Grey
  },
  passportImage: {
    width: layout.window.toNormW(400),
    height: layout.window.toNormW(200),
  }
}