import { useNavigate } from "react-router-dom";
import { styles } from './styles';
import { theme } from "../../../constants/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import layout from "../../../constants/layout";
import { useEffect, useState } from "react";
import { fetchKycUsersList } from "../../../redux/thunks/kyc.thunk";
import { APICallStatus } from "../../../constants";
import { getFormattedDateTime } from "../../../service/codeUtils";
import { useDispatch } from "react-redux";
import { setNavigationProps } from "../../../redux/actions/app";

function PendingKycApprovals({

}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);

  const onLoad = async () => {
    const response = await fetchKycUsersList();
    if(response?.status === APICallStatus.SUCCESS) {
      setUsers(response?.usersList);
      console.log(response?.usersList)
    }
  }

  useEffect(() => {
    onLoad();
  }, []);

  const onClickUser = (user) => {
    dispatch(setNavigationProps({
      kycUserDetails: user,
    }));
    navigate('/userDetails');
  }
 
  return (
    <>
      <div className="d-flex flex-column"  style={styles.container}>

        {users?.map(user => <div className="d-flex align-items-center mb-4" style={styles.listItem} onClick={() => onClickUser(user)}>
          <FontAwesomeIcon icon={faUserCircle} fontSize={layout.window.toNormH(24)}/>
          <div className="d-flex flex-column" style={styles.detailContainer}>
            <p className="m-0" style={styles.userName}>{user?.userName}</p>
            <p className="m-0" style={styles.slotDetails}>Preferred Slot: {getFormattedDateTime(user?.preferredDateTime)}</p>
            <p className="m-0" style={styles.addressDetails}>{user?.addressLine1}, {user?.addressLine2}, {user?.city}, {user?.state}</p>
          </div>
          <FontAwesomeIcon icon={faChevronCircleRight} fontSize={layout.window.toNormH(20)} color={theme.COLOR.ThemePrimary}/>
        </div>)}

      </div>
    </>
  );
}

export default PendingKycApprovals;
