export const ACTION_TYPES = {
  APP: {
    NAVIGATION_PROPS: 'NAVIGATION_PROPS',
    LOGGED_IN_USER: 'LOGGED_IN_USER',
  },
  AUTH: {
    REFRESH_TOKEN: 'REFRESH_TOKEN',
    ACCESS_TOKEN: 'ACCESS_TOKEN',
    IS_USER_LOGGED_IN: 'IS_USER_LOGGED_IN',
  },
};
