import layout from "../../../constants/layout";
import { theme } from "../../../constants/theme";

export const styles = {
  container: {
    padding: layout.window.toNormW(22),
    position: 'relative'
  },
  listItem: {
    width: '100%',
    padding: layout.window.toNormW(12),
    borderRadius: layout.window.toNormW(10),
    borderWidth: layout.window.toNormW(1), 
    borderStyle: 'solid',
    borderColor: theme.COLOR.LightGrey,
  },
  detailContainer: {
    flex: 1,
    paddingLeft: layout.window.toNormW(12),
  },
  userName: {
    fontSize: layout.window.toNormH(16),
    fontFamily: theme.FONT.PrimarySemiBold,
  },
  slotDetails: {
    fontSize: layout.window.toNormH(14),
    fontFamily: theme.FONT.PrimarySemiBold,
    color: theme.COLOR.Grey,
  },
  addressDetails: {
    fontSize: layout.window.toNormH(14),
    fontFamily: theme.FONT.PrimarySemiBold,
    color: theme.COLOR.Grey,
    // textOverflow: 'ellipsis',
    // whiteSpace: 'nowrap',
    // overflow: "hidden",
  }
}