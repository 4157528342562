import { useNavigate } from "react-router-dom";
import layout from "../../constants/layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faPowerOff, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { theme } from "../../constants/theme";
import { getPlatform } from "../../constants/codeUtils";
import { saveTokens } from "../../redux/thunks/auth.thunk";

const styles = {
  title: {
    fontSize: layout.window.toNormH(24),
    fontFamily: theme.FONT.PrimarySemiBold
  },
  headerNavBar: {
    position: 'fixed',
    padding: layout.window.toNormW(22),
    paddingTop: getPlatform() === 'iOS' ? layout.window.toNormH(62) : layout.window.toNormH(22),
    paddingBottom: layout.window.toNormH(16),
    backgroundColor: 'white',
    alignItems: 'center',
    width: '100%',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    zIndex: 10,
  },
  headerNavBarEmptyContainer: {
    height: getPlatform() === 'iOS' ? layout.window.toNormH(116) : layout.window.toNormH(86), // add 30 when app fixed
    width: '100%',
  },
  backButtonContainer: {
    width: layout.window.toNormW(40),
    height: layout.window.toNormW(40),
    // backgroundColor: 'red',
    alignSelf: 'center',
    justifyContent: 'flex-start',
    alignItems: 'center',
  }
}

function Header({
  title,
  showBackButton,
}) {
  const navigate = useNavigate();

  const logout = () => {
    saveTokens({
      accessToken: '',
      refreshToken: '',
    });
    navigate('/');
  }
 
  return (
    <>
      <div className="d-flex justify-content-between" style={styles.headerNavBar}>
        <div className="d-flex">
          {showBackButton ? <span className="d-flex" style={styles.backButtonContainer} onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faArrowLeft} size={layout.window.toNormH(26)}/>
          </span> : null}
          {<p id={`nav-title-${title?.toLowerCase().split(' ').join('-')}`} className="mb-0" style={styles?.title}>{title}</p>}
        </div>
        <FontAwesomeIcon icon={faRightFromBracket} color={theme.COLOR.Red} onClick={logout}/>
      </div>
      <div className="d-flex" style={styles.headerNavBarEmptyContainer} />
    </>
  );
}

export default Header;
