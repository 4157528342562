import React, { useState } from 'react'
import { FloatingLabel, Form } from 'react-bootstrap'
import layout from '../../constants/layout'
import { theme } from '../../constants/theme'

const styles = {
  inputContainer: {
    width: '100%',
  },
  inputTitle: {
    fontSize: 16
  },
  input: {
    width: '100%',
    height: '50px', 
    outline: 'none',
    borderWidth: layout.window.toNormW(1),
    borderColor: theme.COLOR.LightGrey,
    borderRadius: layout.window.toNormW(6),
    paddingLeft: layout.window.toNormW(12),
  },
  activeInput: {
    width: '100%',
    height: '50px', 
    outline: 'none',
    borderWidth: layout.window.toNormW(1),
    borderColor: theme.COLOR.ThemePrimary,
    borderRadius: layout.window.toNormW(6),
    paddingLeft: layout.window.toNormW(12),
  },
}

export default function Input({
  label,
  placeholder,
  value,
  onChange,
  inputContainerStyles = {},
  disabled=false,
}) {
  const [isActive, setIsActive] = useState(false);

  return (
    <div style={{...styles.inputContainer, ...inputContainerStyles}}>
      <Form.Group>
        {label ? <Form.Label style={{fontSize: layout.window.toNormH(16),
    fontFamily: theme.FONT.PrimarySemiBold,
    color: theme.COLOR.Grey}}>{label}</Form.Label> : null}
        {/* <Form.Control value={value} type="text" placeholder={placeholder ? placeholder : ''} style={styles.input} onChange={(e) => onChange(e?.target?.value)}/> */}
        <input style={isActive ? styles.activeInput : styles.input} value={value} type="text" placeholder={placeholder ? placeholder : ''} onChange={(e) => onChange(e?.target?.value)} onFocus={() => setIsActive(true)} onBlur={() => setIsActive(false)} disabled={disabled}></input>
      </Form.Group>
    </div>
  )
}
