import { APICallStatus } from "../../constants";
import { Config } from "../../constants/config";
import { ApiService } from "../../service/ApiService";

export const fetchClosureTickets = async () => {
  try {
    const response = await ApiService.postApi(Config.apis.accounts.closureTickets, {
    });
    console.log(response);
    return {
      status: APICallStatus.SUCCESS,
      tickets: response?.data?.data,
    };
  } catch (error) {
    return {
      status: APICallStatus.ERROR,
      message: error?.data?.error?.shortMessage
    };
  }
}

export const fetchClosureWalletBalance = async ({uuid}) => {
  try {
    const response = await ApiService.postApi(Config.apis.accounts.walletBalance, {
      uuid: uuid,
    });
    console.log(response);
    return {
      status: APICallStatus.SUCCESS,
      balance: response?.data?.data?.balance,
    };
  } catch (error) {
    return {
      status: APICallStatus.ERROR,
      message: error?.data?.error?.shortMessage
    };
  }
}

export const actionClosureRequest = async ({uuid, ticketId, action, comment}) => {
  try {
    const response = await ApiService.postApi(Config.apis.accounts.closeRequest, {
      uuid: uuid,
      ticketId: ticketId,
      action: action,
      comment: comment
    });
    console.log(response);
    return {
      status: APICallStatus.SUCCESS,
    };
  } catch (error) {
    return {
      status: APICallStatus.ERROR,
      message: error?.data?.error?.shortMessage
    };
  }
}