import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './redux/store';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAv2vpi7bjH5i9uH4kKAJvrWuw7SZFEepw",
  authDomain: "mony-kyc-dashboard.firebaseapp.com",
  projectId: "mony-kyc-dashboard",
  storageBucket: "mony-kyc-dashboard.appspot.com",
  messagingSenderId: "977686866638",
  appId: "1:977686866638:web:1d89a4635ab5b686e8be9f",
  measurementId: "G-PPMSSTZPFB"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
