import { useNavigate } from "react-router-dom";
import { styles } from './styles';
import { theme } from "../../../constants/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faCheckSquare, faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { APICallStatus } from "../../../constants";
import { fetchAuditorKycUserList } from "../../../redux/thunks/kyc.thunk";
import AuditorUserDetailsModal from "../../modals/AuditorUserDetailsModal";
import layout from "../../../constants/layout";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { showInfoToast } from "../../../service/toast";

function AuditorDashboard({

}) {
  const options = {
    PENDING_APPROVALS: 'PENDING_APPROVALS',
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED',
    TOTAL_KYC: 'TOTAL_KYC'
  }
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [modalUserData, setModalUserData] = useState();
  const [selectedOption, setSelectedOption] = useState(options.PENDING_APPROVALS);

  const onLoad = async () => {
    const response = await fetchAuditorKycUserList();
    console.log(response);
    if(response?.status === APICallStatus.SUCCESS) {
      setUsers(response?.usersList);
    }
  }

  useEffect(() => {
    onLoad();
  }, []);

  const handleModalClose = () => {
    onLoad();
    setModalUserData(null)
  }
 
  return (
    <>
      <div className="d-flex flex-row"  style={styles.container}>
        <div className="d-flex flex-column justify-content-center" style={selectedOption === options.PENDING_APPROVALS ? styles.selectedItemCard : styles.itemCard} onClick={() => navigate("/pendingUserList")}>
          <p className="m-0" style={styles.itemTitle}>Pending Approvals</p>
          <p className="m-0" style={styles.itemCount}>1</p>
        </div>

        <div className="d-flex flex-column justify-content-center" style={{...styles.itemCard, backgroundColor: theme.COLOR.SuccessGreenOpaque}} onClick={() => showInfoToast("Not yet available")}>
          <p className="m-0" style={styles.itemTitle}>Approved</p>
          <p className="m-0" style={styles.itemCount}>0</p>
        </div>

        <div className="d-flex flex-column justify-content-center" style={{...styles.itemCard, backgroundColor: theme.COLOR.RedOpaque}}>
          <p className="m-0" style={styles.itemTitle}>Rejected</p>
          <p className="m-0" style={styles.itemCount}>0</p>
        </div>

        <div className="d-flex flex-column justify-content-center" style={{...styles.itemCard, backgroundColor: theme.COLOR.LightGrey, marginRight: 0}}>
          <p className="m-0" style={styles.itemTitle}>Total KYCs</p>
          <p className="m-0" style={styles.itemCount}>0</p>
        </div>
      </div>
      <div className="d-flex flex-column" style={styles.listContainer}>
        <div className="d-flex flex-row justify-space-between" style={styles.tableHeader}>

          <div className="d-flex flex-row justify-content-center" style={styles.tableRowItem}>
            <p style={styles.tableHeaderText}>Full Name</p>
          </div>

          <div className="d-flex flex-row justify-content-center" style={styles.tableRowItem}>
            <p style={styles.tableHeaderText}>Mobile Number</p>
          </div>

          <div className="d-flex flex-row justify-content-center" style={styles.tableRowItem}>
            <p style={styles.tableHeaderText}>Country</p>
          </div>

          <div className="d-flex flex-row justify-content-center" style={styles.tableRowItem}>
            <p style={styles.tableHeaderText}>Submitted Date</p>
          </div>

          <div className="d-flex flex-row justify-content-center" style={styles.tableRowItem}>
            <p style={styles.tableHeaderText}>Agent approval</p>
          </div>

          <div className="d-flex flex-row justify-content-center" style={styles.tableRowLastItem}>
            {/* <p style={styles.tableHeaderText}>Agent approval</p> */}
          </div>

        </div>

        <div className="d-flex flex-column" style={styles.tableRowsContainer}>
          {users?.length > 0 ? users.map(user => <div className="d-flex flex-row justify-space-between" style={styles.tableRow} onClick={() => setModalUserData(user)}>
              <div className="d-flex flex-row justify-content-center" style={styles.tableRowItem}>
              <p style={styles.tableHeaderText}>{user?.userName}</p>
            </div>

            <div className="d-flex flex-row justify-content-center" style={styles.tableRowItem}>
              <p style={styles.tableHeaderText}>{user?.userPhoneNumber}</p>
            </div>

            <div className="d-flex flex-row justify-content-center" style={styles.tableRowItem}>
              <p style={styles.tableHeaderText}>{user?.userCountryName}</p>
            </div>

            <div className="d-flex flex-row justify-content-center" style={styles.tableRowItem}>
              <p style={styles.tableHeaderText}>12-12-2023</p>
            </div>

            <div className="d-flex flex-row justify-content-center" style={styles.tableRowItem}>
              <p style={styles.tableHeaderText}><FontAwesomeIcon icon={faCheckSquare} color={theme.COLOR.SuccessGreen} fontSize={layout.window.toNormH(26)}/></p>
            </div>

            <div className="d-flex flex-row justify-content-center align-items-center" style={styles.tableRowLastItem}>
              <FontAwesomeIcon icon={faChevronCircleRight} color={theme.COLOR.ThemePrimary} fontSize={layout.window.toNormH(26)}/>
            </div>
          </div>) : <div className="d-flex flex-column justify-content-center align-items-center" style={styles.emptyContainer}>
            <FontAwesomeIcon icon={faTimesCircle} style={styles.crossIcon}/>
            <p style={styles.emptyText}>No records found</p>
          </div>}
        </div>
      </div>
      <AuditorUserDetailsModal visible={modalUserData} userDetails={modalUserData} onClose={handleModalClose}/>
    </>
  );
}

export default AuditorDashboard;
