import { APICallStatus } from "../../constants";
import { AuthService } from "../../service/AuthService";
import { StorageService } from "../../service/StorageService";
// import { setUserProfile } from "../actions/app";
import { setAccessToken, setIsUserLoggedIn, setRefreshToken } from "../actions/auth";
import { accessTokenSelector } from "../selectors/auth";
import store from "../store";

export const saveTokens = ({accessToken, refreshToken}) => {
  try {
    const dispatch = store.dispatch;
    dispatch(setAccessToken(accessToken));
    dispatch(setRefreshToken(refreshToken));
    StorageService.persistAccessToken(accessToken);
    StorageService.persistRefreshToken(refreshToken);
  } catch (error) {
    
  }
}

// export const checkUserLoggedIn = async () => {
//   try {
//     const dispatch = store.dispatch;
//     const accessToken = StorageService.getAccessToken();
//     const refreshToken = StorageService.getAccessToken();

//     if(!accessToken || !refreshToken) {
//       return false;
//     }
//     dispatch(setAccessToken(accessToken));
//     dispatch(setRefreshToken(refreshToken));

//     const tokenValidity = await AuthService.checkTokenValidity();
//     if(tokenValidity?.status === APICallStatus.SUCCESS) {
//       dispatch(setIsUserLoggedIn(true));
//       return true;
//     }
//     return false;
//   } catch (error) {
//     return false;
//   }
// }

// export const logout = async () => {
//   saveTokens({accessToken: null, refreshToken: null});
//   const dispatch = store.dispatch;
//   dispatch(setIsUserLoggedIn(false));
// }

// export const fetchUserProfile = async () => {
//   try {
//     const response = await AuthService.fetchUserProfile({});
//     const dispatch = store.dispatch;
//     dispatch(setUserProfile(response?.data));
//     return {
//       status: APICallStatus.SUCCESS,
//     }
//   } catch (error) {
//     return {
//       status: APICallStatus.ERROR,
//       message: error?.data?.error || 'Something went wrong',
//     }
//   }
// }