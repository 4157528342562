export const getFormattedDateTime = (dateTimestamp) => {
  const timestamp = new Date(Number(dateTimestamp));
  const month = timestamp?.getMonth()+1 < 10 ? `0${timestamp?.getMonth()+1}` : timestamp?.getMonth()+1;
  const year = timestamp?.getFullYear();
  const date = timestamp?.getDate() < 10 ? `0${timestamp?.getDate()}` : timestamp?.getDate();
  let hours = timestamp?.getHours() < 13 ? timestamp?.getHours() : timestamp?.getHours()-12;
  hours = hours === 0 ? 12 : hours;
  const minutes = timestamp?.getMinutes() < 10 ? `0${timestamp?.getMinutes()}` : timestamp?.getMinutes();
  const ampm = timestamp?.getHours() < 12 ? `AM` : `PM`;
  return `${date}-${month}-${year} | ${hours < 10 ? `0${hours}` : hours}:${minutes} ${ampm}`;
}