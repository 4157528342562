import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { theme } from "../../constants/theme";
import { accessTokenSelector, isUserLoggedInSelector } from "../../redux/selectors/auth";
import store from "../../redux/store";
import { ToastContainer } from "react-toastify";
const ProtectedRoute = ({ children }) => {
  const accessToken = accessTokenSelector(store.getState());

  if (!(accessToken && accessToken?.length > 0)) {
    return <Navigate to="/" />;
  }
  return <div style={{flex: 1, minHeight: '100vh', width: '100%', fontFamily: theme.FONT.PrimaryRegular}}>
    {children}
  </div>;
};

export default ProtectedRoute;
