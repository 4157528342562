import { ACTION_TYPES } from '../constants/actionTypes';

const setNavigationProps = (data) => ({
  type: ACTION_TYPES.APP.NAVIGATION_PROPS,
  data,
});

const setLoggedInUser = (data) => ({
  type: ACTION_TYPES.APP.LOGGED_IN_USER,
  data,
});

export {
  setNavigationProps,
  setLoggedInUser,
};
