import React, { useEffect, useState } from 'react'
import { Button, Dropdown, DropdownButton, Modal } from 'react-bootstrap'
import { styles } from './styles'
import { getFormattedDateTime } from '../../../service/codeUtils'
import Input from '../../../elements/Input'
import { auditorKycSubmit, fetchAllAgents, mapBookingToAgent } from '../../../redux/thunks/kyc.thunk'
import CustomButton from '../../../elements/CustomButton'
import { APICallStatus, KycAction } from '../../../constants'
import layout from '../../../constants/layout'
import { showErrorToast } from '../../../service/toast'

export default function AgentUserMapModal({
  visible,
  userDetails,
  onClose,
}) {

  const [isLoading, setIsLoading] = useState(false);
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState({});

  const onSubmit = async () => {
    setIsLoading(true);
    const response = await mapBookingToAgent({
      bookingId: userDetails?.bookingId,
      agentUuid: selectedAgent?.uuid,
    });
    setIsLoading(false);
    if(response?.status === APICallStatus.SUCCESS) {
      onClose();
    } else {
      showErrorToast(response?.message);
    }
  }

  const fetchAgents = async () => {
    const response = await fetchAllAgents();
    if(response?.status === APICallStatus.SUCCESS) {
      setAgents(response?.agents);
      response?.agents?.length > 0 &&  setSelectedAgent(response?.agents[0]);
    } else {
      showErrorToast(response?.message);
    }
  }

  useEffect(() => {
    if(visible) {
      fetchAgents();
    }
  }, [visible])

  return (
    <Modal show={visible} onHide={onClose} size="lg" >
      <Modal.Header closeButton>
        <Modal.Title>User details</Modal.Title>
      </Modal.Header>
      <Modal.Body className='d-flex flex-column align-items-center'>
        <img src={userDetails?.userProfilePhoto} style={styles.userProfile}/>
        <p className="mb-0" style={styles.userName}>{userDetails?.userName}</p>
        <p className="mb-0" style={styles.country}>{userDetails?.userCountryName}</p>

        <div style={styles.horizontalLine}/>

        <div className="d-flex justify-content-between" style={{width: '100%'}}>
          <div style={styles.fieldContainer}>
            <p className="m-0" style={styles.fieldTitle}>Contact Number</p>
            <a className="m-0" style={styles.fieldValue}>{userDetails?.userPhoneNumber}</a>
          </div>
          <div className="d-flex flex-column align-items-end" style={styles.fieldContainer}>
            <p className="m-0" style={styles.fieldTitle}>Alternate Number</p>
            {userDetails?.alternatePhoneNumber ? <a className="m-0" style={styles.fieldValue}>{userDetails?.alternatePhoneNumber}</a> : <p className="m-0" style={styles.fieldValue}>--</p>}
          </div>
        </div>

        <div className='d-flex flex-column' style={styles.fieldContainer}>
          <p className="m-0" style={styles.fieldTitle}>Preferred Date and Time</p>
          <a className="m-0" style={styles.fieldValue}>{getFormattedDateTime(userDetails?.preferredDateTime)}</a>
        </div>

        <div className='d-flex flex-column' style={styles.fieldContainer}>
          <p className="m-0" style={styles.fieldTitle}>Current Address</p>
          <a className="m-0" style={styles.fieldValue}>{userDetails?.addressLine1}, {userDetails?.addressLine2}, {userDetails?.city}, {userDetails?.state} {userDetails?.pincode}</a>
        </div>

        <div style={styles.horizontalLine}/>

        <div className='d-flex flex-column' style={styles.fieldContainer}>
          <p className="m-0 mb-2" style={styles.fieldTitle}>Select Agent</p>
          <DropdownButton title={selectedAgent?.phoneNumber} style={styles.dropdownBtn}>
            {agents.map(agent => <Dropdown.Item onClick={() => setSelectedAgent(agent)}>{agent?.phoneNumber}</Dropdown.Item>)}
          </DropdownButton>
          {/* <a className="m-0" style={styles.fieldValue}>{userDetails?.addressLine1}, {userDetails?.addressLine2}, {userDetails?.city}, {userDetails?.state} {userDetails?.pincode}</a> */}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className='d-flex flex-row'>
          <CustomButton title={"Assign"} onClick={onSubmit} isLoading={isLoading} buttonStyles={{flex: 1}}/>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
