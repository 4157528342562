import { StorageKeys } from "../../constants/storageKeys";

export const persistAccessToken = (value) => {
  localStorage.setItem(StorageKeys.ACCESS_TOKEN, value);
}

export const getAccessToken = () => {
  return localStorage.getItem(StorageKeys.ACCESS_TOKEN);
}

export const persistRefreshToken = (value) => {
  localStorage.setItem(StorageKeys.REFRESH_TOKEN, value);
}

export const getRefreshToken = () => {
  return localStorage.getItem(StorageKeys.REFRESH_TOKEN);
}

export const StorageService = {
  persistAccessToken,
  getAccessToken,
  persistRefreshToken,
  getRefreshToken,
}