import layout from "../../constants/layout";

export const styles = {
  loaderContainer: {
    width: '100%',
    height: '100vh',
    display: 'flex', 
    flex: 1,
    justifyContent: 'center', 
    alignItems: 'center',
  },
  container: {
    width: '100%',
    height: '100vh',
    display: 'flex', 
    flex: 1,
    justifyContent: 'center', 
    alignItems: 'center',
  },
  errorText: {
    fontSize: layout.window.toNormH(20),
    lineHeight: layout.window.toNormH(24),
  }
}