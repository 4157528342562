import { useNavigate } from "react-router-dom";
import { styles } from './styles';
import { theme } from "../../../constants/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

function AgentDashboard({

}) {
  const navigate = useNavigate();
 
  return (
    <>
      <div className="d-flex flex-column"  style={styles.container}>
        <div className="d-flex flex-column justify-content-center" style={styles.itemCard} onClick={() => navigate("/pendingUserList")}>
          <p className="m-0" style={styles.itemTitle}>Pending Approvals</p>
          <p className="m-0" style={styles.itemCount}>1</p>
        </div>

        <div className="d-flex flex-column justify-content-center" style={{...styles.itemCard, backgroundColor: theme.COLOR.SuccessGreenOpaque}}>
          <p className="m-0" style={styles.itemTitle}>Approved</p>
          <p className="m-0" style={styles.itemCount}>0</p>
        </div>

        <div className="d-flex flex-column justify-content-center" style={{...styles.itemCard, backgroundColor: theme.COLOR.RedOpaque}}>
          <p className="m-0" style={styles.itemTitle}>Rejected</p>
          <p className="m-0" style={styles.itemCount}>0</p>
        </div>

        <div className="d-flex flex-column justify-content-center" style={{...styles.itemCard, backgroundColor: theme.COLOR.LightGrey}}>
          <p className="m-0" style={styles.itemTitle}>Total KYCs</p>
          <p className="m-0" style={styles.itemCount}>0</p>
        </div>
      </div>
    </>
  );
}

export default AgentDashboard;
