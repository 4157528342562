import axios from "axios";
import { Config } from "../constants/config";
import { accessTokenSelector } from "../redux/selectors/auth";
import store from "../redux/store";

// const getHeaders = () => {
//   const { logOut, user } = useUserAuth();

//   return {
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${user.accessToken}`,
//     },
//   };
// };


const postApi = (url, params) => {
  // let accessToken = '';
  // if(window.location.href?.split('?')?.length > 1) {
  //   const queryParams = window.location.href?.split('?')[1].split('&');
  //   queryParams.forEach(param => {
  //     if(param.indexOf('accessToken') > -1) {
  //       accessToken = param?.split('=')[1];
  //     }
  //   });
  // }
  const accessToken = accessTokenSelector(store.getState());
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
      source: 'web',
      deviceid: Math.random(),
    },
  };
  const body = {
    metaData: {
    },
    data: {
      ...params
    },
  };
  console.log(body, 'api request body')
  return axios.post(
    `${process.env.REACT_APP_BACKEND_DOMAIN}${url}`,
    body,
    headers,
  );
}

const postTokenApi = (url, params) => {
  let accessToken = '';
  if(window.location.href?.split('?')?.length > 1) {
    const queryParams = window.location.href?.split('?')[1].split('&');
    queryParams.forEach(param => {
      if(param.indexOf('accessToken') > -1) {
        accessToken = param?.split('=')[1];
      }
    });
    if(accessToken?.length === 0) {
      return;
    }
  } else {
    return;
  }
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
      source: 'web',
      deviceid: Math.random(),
    },
  };

  const body = {
    metaData: {
      // userId: 22,
    },
    data: {
      ...params
    },
  };
  return axios.post(
    `${process.env.REACT_APP_BACKEND_DOMAIN}${url}`,
    body,
    headers,
  );
}

const sendPushNotification = async (params) => {
  return await postApi(Config.apis.sendPushNotification, params);
}

export const ApiService = {
  sendPushNotification,
  postApi,
  postTokenApi,
}