import React from "react";
import { Button } from "react-bootstrap";
import { theme } from "../../constants/theme";
import { RotatingLines } from "react-loader-spinner";
import layout from "../../constants/layout";

const styles = {
  container: {
    // position: "fixed",
    // bottom: 0,
    width: "100%",
    // paddingLeft: layout.window.toNormW(22),
    // paddingRight: layout.window.toNormW(22),
    // paddingTop: layout.window.toNormH(22),
    // paddingBottom: layout.window.toNormH(32),
    backgroundColor: theme.COLOR.WhitePrimary,
    // boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
  },
  button: {
    width: "100%",
    backgroundColor: theme.COLOR.ThemePrimary,
    paddingTop: layout.window.toNormH(12),
    paddingBottom: layout.window.toNormH(12),
  },
  buttonDisabled: {
    width: "100%",
    backgroundColor: theme.COLOR.Grey,
  },
  buttonTitle: {
    fontSize: layout.window.toNormH(20),
    fontFamily: theme.FONT.PrimarySemiBold,
    color: theme.COLOR.WhitePrimary
  },
  poweredText: {},
  rivexLogo: {
    width: layout.window.toNormW(60),
    height: layout.window.toNormW(21),
    marginLeft: layout.window.toNormW(6),
  },
  aboveDescriptionText: {
    fontSize: layout.window.toNormH(16),
    fontFamily: theme.FONT.PrimarySemiBold,
    color: theme.COLOR.Grey,
    textAlign: 'center'
  },
  stripeLogo: {
    width: layout.window.toNormW(60),
    height: layout.window.toNormH(30),
  }
};

export default function CustomButton({ onClick, isLoading, isDisabled, title, style, buttonStyles }) {
  return (
    <div className="" style={{...styles.container, ...style}}>
      <Button
        // className="py-2"
        style={{...styles.button, ...buttonStyles}}
        onClick={onClick}
        disabled={isLoading || isDisabled}
      >
        {!isLoading ? <p className="m-0 p-0" style={styles.buttonTitle}>
          {title}
        </p> :
        <RotatingLines
          strokeColor="white"
          strokeWidth={layout.window.toNormW(5)}
          animationDuration="0.75"
          width={layout.window.toNormW(22)}
          visible={true}
        />}
      </Button>
    </div>
  );
}
